import actions from './actions'

const initialState = {
  id: '',
  name: '',
  role: '',
  email: '',
  avatar: '',
  accesstoken: '',
  authorized: false,
  authenticated: false,
  loading: false,
  MaterialType: '',
  IsImpersonate: false,
  reftype: '',
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case "user/GETUSERNAME":
      return {
        ...state,
        name: action.payload.name,
        authenticated: action.payload.authenticated
      }
    default:
      return state
  }
}
