// import { logout } from './OIDCService'
// import urlConstant from '../constants/urlConstant'
import { endApiLoader, startApiLoader } from './apiLoaderService'
import dataConstant from '../constants/dataConstants'

const CryptoJS = require('crypto-js')

export const httpGet = async (requestURL, authorizationRequired, enableLoader = true, accessToken = null,impersonateEmail=null) =>
  new Promise((resolve, reject) => {
    const options = {
      method: 'get',
      headers: {
        'content-type': 'application/json; charset=UTF-8',
        'impersonateEmail':impersonateEmail
      },
    }
    try {
      if (authorizationRequired && accessToken) {
        options.headers.Authorization = `bearer ${accessToken}`
      }
      if (enableLoader) {
        startApiLoader()
      }
      fetch(requestURL, options)
        .then(
          response => {
            // if (response.status === 404 || response.status === 400 || response.status === 500) {
            //   this.AddErrorLog(response.status, response.url)
            // }
            if (response.status === 404 || response.status === 200 || response.status === 400) {
              return response.json()
            }
            return response
          },
          err => {
            reject(err)
          },
        )
        .then(
          responseJson => {
            if (responseJson.status === 401) {
              // TODO Handle Signout
            }
            resolve(responseJson)
          },
          err => {
            // this.AddErrorLog(err.message, err.stack)
            reject(err)
          },
        )
        .catch(err => {
          // this.AddErrorLog(err.message, err.stack)
          reject(err)
        })
        .finally(() => {
          if (enableLoader) {
            endApiLoader()
          }
        })
    } catch (error) {
      console.warn(error)
    }
  })

export const httpPost = async (requestURL, requestBody, authorizationRequired, enableLoader = true, accessToken = null,impersonateEmail=null) =>
  new Promise((resolve, reject) => {
    const options = {
      method: 'post',
      headers: {
        'content-type': 'application/json; charset=UTF-8',
        'impersonateEmail':impersonateEmail
      },
      body: JSON.stringify(requestBody),
    }

    try {
      if (authorizationRequired && accessToken) {
        options.headers.Authorization = `bearer ${accessToken}`
      }
      if (enableLoader) {
        startApiLoader()
      }
      fetch(requestURL, options)
        .then(
          response => {
            // if (response.status === 404 || response.status === 400 || response.status === 500) {
            //   this.AddErrorLog(response.status, response.url)
            // }
            if (response.status === 404 || response.status === 200 || response.status === 400) {
              return response.json()
            }
            return response
          },
          err => {
            reject(err)
          },
        )
        .then(
          responseJson => {
            if (responseJson.status === 401) {
              // TODO Handle Signout
            }
            resolve(responseJson)
          },
          err => {
            // this.AddErrorLog(err.message, err.stack)
            reject(err)
          },
        )
        .catch(err => {
          // this.AddErrorLog(err.message, err.stack)
          reject(err)
        })
        .finally(() => {
          if (enableLoader) {
            endApiLoader()
          }
        })
    } catch (error) {
      console.warn(error)
    }
  })

export const encryptData = data => {
  const key = CryptoJS.enc.Utf8.parse(dataConstant.AESEncryptDecryptKey)
  const iv = CryptoJS.enc.Utf8.parse(dataConstant.AESEncryptDecryptKey)

  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  return encrypted.toString();
}

export const DecryptData = data => {
  const key = CryptoJS.enc.Utf8.parse(dataConstant.AESEncryptDecryptKey)
  const iv = CryptoJS.enc.Utf8.parse(dataConstant.AESEncryptDecryptKey)
  const decrypted = CryptoJS.AES.decrypt(data, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  return decrypted.toString(CryptoJS.enc.Utf8)
}

export const hideScrollbar = () => {
  document.body.style.overflow = 'hidden';
}

export const showScrollbar = () => {
  document.body.style.overflow = 'visible';
}

export const DocumentUpload = async (requestURL, formData, authorizationRequired, enableLoader = true, accessToken = null,impersonateEmail = null) =>
  new Promise((resolve, reject) => {
    const options = {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'impersonateEmail':impersonateEmail
      },
      body: formData,
    }

    if (enableLoader) {
      startApiLoader()
    }
    try {
      if (authorizationRequired && accessToken) {
        options.headers.Authorization = `bearer ${accessToken}`
      }
      if (enableLoader) {
        startApiLoader()
      }
      fetch(requestURL, options)
        .then(
          response => {
            // if (response.status === 404 || response.status === 400 || response.status === 500) {
            //  this.AddErrorLog(response.status, response.url)
            // }
            if (response.status === 404 || response.status === 200 || response.status === 400) {
              return response.json()
            }
            return response
          },
          err => {
            reject(err)
          },
        )
        .then(
          responseJson => {
            if (responseJson.status === 401) {
              // TODO Handle Signout
            }
            resolve(responseJson)
          },
          err => {
            // this.AddErrorLog(err.message, err.stack)
            reject(err)
          },
        )
        .catch(err => {
          // this.AddErrorLog(err.message, err.stack)
          reject(err)
        })
        .finally(() => {
          if (enableLoader) {
            endApiLoader()
          }
        })
    } catch (error) {
      console.warn(error)
    }
  })

