import React from 'react'
import ScrollToTop from 'react-scroll-up'
import { Button } from 'reactstrap'
import { ArrowUp } from 'react-feather'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import { DropDownList } from '@progress/kendo-react-dropdowns'
const locales = ['en', 'es']

const Footer = props => {
  const defaultLanguage = localStorage.getItem('i18nextLng') !== null ? localStorage.getItem('i18nextLng') : 'en'
  const { onLocaleChange, t } = props
  let footerTypeArr = ['sticky', 'static', 'hidden']
  return (
    <footer
      className={classnames('footer footer-light', {
        'footer-static': props.footerType === 'static' || !footerTypeArr.includes(props.footerType),
        'd-none': props.footerType === 'hidden',
      })}
    >
      <p className="mb-0 clearfix">
        <span className="float-md-left d-block d-md-inline-block mt-25">
          {t('Footer.COPYRIGHT')} © {new Date().getFullYear()} BYLD Network, {t('Footer.All rights reserved')}
        </span>
        <span className="float-right d-sm-block">
          <DropDownList data={locales} value={defaultLanguage} onChange={onLocaleChange} />
        </span>
      </p>
      {props.hideScrollToTop === false ? (
        <div className='cm-btn-btt'>
        <ScrollToTop showUnder={160} className="jayesh">
          <Button color="primary" className="btn-icon scroll-top">
            <ArrowUp size={15} />
          </Button>
        </ScrollToTop>
        </div>
      ) : null}
    </footer>
  )
}

export default withTranslation()(Footer)
