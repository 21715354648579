import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import user from "../user/reducers"
import navbar from "./navbar/Index"
import apiLoaderReducer from "../apiLoader/reducers"
import promotionSidebar from "./promotionSidebar/"

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  user: user,
  navbar: navbar,
  apiLoader: apiLoaderReducer,
  promotionSidebar: promotionSidebar
})

export default rootReducer
