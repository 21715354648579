import React from "react"
import { Link } from "react-router-dom"
import { Badge } from "reactstrap"
import classnames from "classnames"
import { ChevronRight } from "react-feather"
import _ from 'underscore'
import { withTranslation } from 'react-i18next'
import dataConstant from "../../../../../constants/dataConstants"

let selectedId = 0

class SideMenuGroup extends React.Component {
  constructor(props) {
    super(props)
    this.flag = true
    this.parentArray = []
    this.childObj = {}
    this.state = {
      isOpen: {},
      activeItem: this.props.activePath
    }
  }

  handleActiveItem = url => {
    this.setState({
      activeItem: url
    })
  }

  handleToggle = (itemId) => {
    this.setState(prevState => {
      const newIsOpen = { ...prevState.isOpen }

      Object.keys(newIsOpen).forEach(key => {
        newIsOpen[key] = false
      })

      newIsOpen[itemId] = !newIsOpen[itemId]

      return { isOpen: newIsOpen }
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activePath !== this.props.activePath) {
      if (this.props.activePath === '/mediaPage' && this.props.activePath === this.childObj.navLink) {
        if (this.childObj.MediaId === this.props.activeMenuMediaId) {
          this.props.parentArr.splice(0, this.props.parentArr.length)
          this.props.parentArr.push(this.parentArray)
        }
      } else if (
        this.props.activePath === this.childObj.navLink &&
        !this.props.parentArr.includes(this.parentArray[0])
      ) {
        this.props.parentArr.splice(0, this.props.parentArr.length)
        this.props.parentArr.push(this.parentArray)
      } else if (this.props.parentArr.includes(this.parentArray)) {
        this.props.parentArr.splice(0, this.props.parentArr.length)
      }
    }
  }

  renderChild(item, activeGroup, handleGroupClick, handleActiveItem) {
    const { t } = this.props

    if (item.children || item.InnerChildren) {
      if (item.children) {
        const mediaPageChild = _.find(item.children, i => i.navLink === this.props.activeItemState && i.MediaId === this.props.activeMenuMediaId)
        if (mediaPageChild !== dataConstant.Undefined) {
          selectedId = mediaPageChild.id
        } else {
          const firstMediaPage = _.find(item.children, i => i.navLink === '/mediaPage' && i.MediaId === this.props.activeMenuMediaId)
          if (firstMediaPage !== dataConstant.Undefined) {
            selectedId = firstMediaPage.id
          }
        }
      } else {
        const mediaPageChild = _.find(item.InnerChildren, i => i.navLink === this.props.activeItemState && i.MediaId === this.props.activeMenuMediaId)
        if (mediaPageChild !== dataConstant.Undefined) {
          selectedId = mediaPageChild.id
        } else {
          const firstMediaPage = _.find(item.InnerChildren, i => i.navLink === '/mediaPage' && i.MediaId === this.props.activeMenuMediaId)
          if (firstMediaPage !== dataConstant.Undefined) {
            selectedId = firstMediaPage.id
          }
        }
      }
    }

    return (
      <ul className="menu-content">
        {[...(item.children || []), ...(item.InnerChildren || [])].map(child => {
          const CustomAnchorTag =
            child.type === "external-link" ? `a` : Link;

          if (!this.parentArray.includes(item.id) && this.flag) {
            this.parentArray.push(item.id)
          }

          if (child.navlink && child.collapsed) {
            this.props.collapsedMenuPaths(child.navLink)
          }
          if (this.props.activeItemState === '/mediaPage' && this.props.activeItemState === child.navLink) {
            if (child.MediaId === this.props.activeMenuMediaId) {
              this.childObj = child
              this.props.parentArr.push(this.parentArray)
              this.flag = false
            }
          } else if (this.props.activeItemState === child.navLink) {
            this.childObj = child
            this.props.parentArr.push(this.parentArray)
            this.flag = false
          }
          if (child.permissions === dataConstant.Undefined) {
            return (
              <li
                key={child.id}
                onClick={e => {
                  e.stopPropagation()
                  handleGroupClick(child.id, item.id, child.type)
                  if (child.navLink && child.navLink !== undefined) {
                    handleActiveItem(child.navLink)
                  }
                  if (this.props.deviceWidth <= 1200 && child.type === "item") {
                    this.props.toggleMenu()
                  }
                }}
                className={classnames({
                  hover: this.props.hoverIndex === child.id,
                  "has-sub": child.type === "collapse",
                  open: child.type === "collapse" && this.state.isOpen[child.id],
                  "sidebar-group-active": this.props.currentActiveGroup.includes(child.id),
                  active: this.props.activeItemState === '/mediaPage' &&
                    this.props.activeItemState === child.navLink
                    ? ((this.props.activeItemState === child.navLink && child.type === "item" && child.MediaId === this.props.activeMenuMediaId)
                      || (item.parentOf && item.parentOf.includes(this.props.activeItemState)))
                    : (this.props.activeItemState === '/viewOrder/:Id?' ? (
                      child.type === "item" && child.id === selectedId) : (((this.props.activeItemState === child.navLink &&
                        child.type === "item") || (item.parentOf && item.parentOf.includes(this.props.activeItemState)))))
                  ,
                  disabled: child.disabled
                })}
              >
                <CustomAnchorTag
                  className={classnames({
                    "d-flex justify-content-between": child.type === "collapse"
                  })}
                  to={{
                    pathname: child.navLink && child.type === "item"
                      ? child.navLink
                      : "",
                    state: { id: child.MediaId }
                  }}
                  href={child.type === "external-link" ? child.navLink : ""}
                  onMouseEnter={() => this.props.handleSidebarMouseEnter(child.id)}
                  onMouseLeave={() => this.props.handleSidebarMouseEnter(child.id)}
                  onClick={e => {
                    if (child.type === "collapse") {
                      e.preventDefault();
                      this.handleToggle(child.id)
                    }else {
                      this.handleToggle(item.id)
                    }
                  }}
                  target={child.type === "external-link" ? "_blank" : undefined}
                >
                  <div className="menu-text">
                    <i className={child.icon} />
                    <span className="menu-item menu-title">
                      {t(child.title)}
                    </span>
                  </div>
                  {child.badge ? (
                    <Badge
                      color={child.badge}
                      className="float-right mr-2"
                      pill
                    >
                      {child.badgeText}
                    </Badge>
                  ) : (
                    ""
                  )}
                  {child.type === "collapse" ? (
                    <ChevronRight className="menu-toggle-icon" size={13} />
                  ) : (
                    ""
                  )}
                </CustomAnchorTag>

                {(child.children)
                  ? this.renderChild(
                    child,
                    activeGroup,
                    handleGroupClick,
                    handleActiveItem,
                    item.id
                  )
                  : ""}
                {(child.InnerChildren)
                  ? this.renderChild(
                    child,
                    activeGroup,
                    handleGroupClick,
                    handleActiveItem,
                    item.id
                  )
                  : ""}
              </li>
            )
          } else if (
            child.navLink === this.props.activePath
          ) {
            return this.props.redirectUnauthorized()
          } else {
            return null
          }
        })}
      </ul>
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderChild(
          this.props.group,
          this.props.activeGroup,
          this.props.handleGroupClick,
          this.props.handleActiveItem,
          null
        )}
      </React.Fragment>
    )
  }
}

export default withTranslation()(SideMenuGroup)
