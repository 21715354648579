//import React from "react"
//import {Spinner} from "reactstrap"

//class ApiLoader extends React.Component {

//  render() {
//    return(
//      <div className="text-center">
//        <Spinner color="primary" />
//      </div>
//    )
//  }
//}
//export default ApiLoader

import React, { Component } from 'react'
import './apiLoader.style.scss'

class ApiLoader extends Component {
  render() {
    return (
      <>
        <div id="overlay">
          <div className="justify-content-between mx-5 mt-3 mb-5">
            <div className="spinner-border align-self-center">
              <br />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default ApiLoader
