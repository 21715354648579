import { store } from "../redux/storeConfig/store";

export function startApiLoader() {
  store.dispatch({
    type: 'apiLoader/SET_STATE',
    payload: {
      isLoading: true,
    },
  })
}

export function endApiLoader() {
  store.dispatch({
    type: 'apiLoader/SET_STATE',
    payload: {
      isLoading: false,
    },
  })
}
