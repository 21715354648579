import React, { useEffect,useContext,useState } from 'react'
import { Navbar } from 'reactstrap'
import classnames from 'classnames'
import NavbarUser from './NavbarUser'
import NavbarBookmarks from './NavbarBookmarks'
import userImg from '../../../assets/img/portrait/small/avatar-s-11.jpg'
import urlConstant from '../../../constants/urlConstant'
import dataConstant from '../../../constants/dataConstants'
import { httpGet } from '../../../services/commonService'
import { withTranslation } from 'react-i18next'
import { AuthenticationContext } from '@cloudmlm/cloudmlm-auth'

let acctId = 0;

const ThemeNavbar = props => {
  const authenticationState = useContext(AuthenticationContext);
  const colorsArr = ['primary', 'danger', 'success', 'info', 'warning', 'dark']
  const navbarTypes = ['floating', 'static', 'sticky', 'hidden']
  const [userName, setUserName] = useState('')
  const { t } = props
  const path = window.location.pathname
  useEffect(() => {
    getProfilePicDetails()
  }, [])

  const getProfilePicDetails = () => {
    const getProfilePicDetailsURL = urlConstant.User.GetUserProfilePicDetails
    httpGet(
      getProfilePicDetailsURL,
      true,
      true,
      authenticationState.accessToken,
      authenticationState.impersonateEmail,
    )
      .then(response => {
        if (response.Status === dataConstant.APIResponseStatus.Success) {
          const responseData = response.Values
          acctId = responseData.AcctID
          localStorage.setItem('UserName',JSON.stringify(responseData.UserName))   
          setUserName(responseData.UserName)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames('header-navbar navbar-expand-lg navbar navbar-with-menu', {
          'navbar-dark': colorsArr.includes(props.navbarColor),
          'bg-primary': props.navbarColor === 'primary' && props.navbarType !== 'static',
          'bg-danger': props.navbarColor === 'danger' && props.navbarType !== 'static',
          'bg-success': props.navbarColor === 'success' && props.navbarType !== 'static',
          'bg-info': props.navbarColor === 'info' && props.navbarType !== 'static',
          'bg-warning': props.navbarColor === 'warning' && props.navbarType !== 'static',
          'bg-dark': props.navbarColor === 'dark' && props.navbarType !== 'static',
          'd-none': props.navbarType === 'hidden' && !props.horizontal,
          'floating-nav':
            (props.navbarType === 'floating' && !props.horizontal) ||
            (!navbarTypes.includes(props.navbarType) && !props.horizontal),
          'navbar-static-top': props.navbarType === 'static' && !props.horizontal,
          'fixed-top': props.navbarType === 'sticky' || props.horizontal,
          scrolling: props.horizontal && props.scrolling,
        })}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content pl-0">
            <div className="navbar-collapse d-flex align-items-center" id="navbar-mobile">
              <div className="bookmark-wrapper">
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </div>
              {/* {
              path === '/home' && userName ? (
                <h3 className="mb-0 font-weight-bold cm-user-title">
                  {t('Header.Hello')} {userName} {' ('+acctId+')'}, {t('Header.Welcome to your account')}
                </h3>
              ) : (
                <h3 className="mb-0 font-weight-bold cm-user-title">{}</h3>
              )} */}
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">Vuexy</h2>
                </div>
              ) : null}
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName="John Doe"
                userImg={userImg}
                userPrefix={t('Header.Hi')}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}

export default withTranslation()(ThemeNavbar)
