import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { AuthenticationProvider } from '@cloudmlm/cloudmlm-auth'
import { createBrowserHistory } from 'history'
import { Layout } from './utility/context/Layout'
import * as serviceWorker from './serviceWorker'
import { store } from './redux/storeConfig/store'
import './index.scss'
import './@fake-db'
import "./assets/scss/kendo-custom.css"
import ApiLoader from './layouts/components/apiLoader/apiLoader'
import urlConstant from './constants/urlConstant'

const LazyApp = lazy(() => import('./App'))
const history = createBrowserHistory()

// configureDatabase()
ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<ApiLoader />}>
      <AuthenticationProvider userInfoRoleEndpoint={urlConstant.RetailShopping.GetUserRoleByEmail}>
        <Layout>
          <LazyApp history={history} />
        </Layout>
      </AuthenticationProvider>
    </Suspense>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
