const initialState = {
  events: [],
  sidebar: false,
  selectedEvent: null
}

const promotionSidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "HANDLE_SIDEBAR":
      return { ...state, sidebar: action.status }
    case "HANDLE_SELECTED_EVENT":
      return { ...state, selectedEvent: action.event }
    default:
      return state
  }
}

export default promotionSidebarReducer
