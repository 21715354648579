const actions = {
  SET_STATE: 'user/SET_STATE',
  SIGNIN: 'user/SIGNIN',
  SIGNOUT: 'user/SIGNOUT',
  GETUSERNAME: 'user/GETUSERNAME',
  CHANGEPASSWORD: 'user/CHANGEPASSWORD',
  SETUSERAUTHORIZATION: 'user/SETUSERAUTHORIZATION',
}

export default actions
