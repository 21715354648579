import config from '../config/config'

const { apiURL } = config

const urlConstant = {
  Common: {
    LanguageList: `${apiURL}api/Common/GetLanguageDropDownList`,
    GetRegistrationTypeList: `${apiURL}api/Common/GetSupportDataByDataType?DataType=EnrollmentRegistrationType`,
    GetCoutryDropDownList: `${apiURL}api/Common/GetCoutryDropDownList`,
    GetStateList: `${apiURL}api/Common/GetStateDropDownList`,
    GetAllDropDownList: `${apiURL}api/Common/GetAllDropDownListForSignUp`,
    GetApplicablePromotions: `${apiURL}api/Promotion/GetApplicablePromotions`,
    GetAllDropDownData: `${apiURL}api/Common/GetAllDropDownList`,
    GetiWalletStatusList: `${apiURL}api/Common/GetSupportDataByDataType?DataType=iWalletStatus`,
    GetiWalletTransactionType: `${apiURL}api/Common/GetSupportDataByDataType?DataType=iWalletTransactionType`,
    GetYourselfMentorStatusList: `${apiURL}api/Common/GetSupportDataByDataType?DataType=YourSelfMentorStatus`,
    // GetLeadRoofAgeList: `${apiURL}api/Common/GetSupportDataByDataType?DataType=LeadRoofAge`,
    // GetLeadRoofTypeList: `${apiURL}api/Common/GetSupportDataByDataType?DataType=LeadRoofType`,
    // GetLeadFinancingList: `${apiURL}api/Common/GetSupportDataByDataType?DataType=LeadFinancing`,
    // GetLeadMountingPreferenceList: `${apiURL}api/Common/GetSupportDataByDataType?DataType=LeadMountingPreference`,
    // GetLeadWorkList: `${apiURL}api/Common/GetSupportDataByDataType?DataType=LeadWork`,
    // GetLeadKindofElectricalPanelList: `${apiURL}api/Common/GetSupportDataByDataType?DataType=LeadKindofElectricalPanel`,
    // GetLeadStatusList: `${apiURL}api/Common/GetSupportDataByDataType?DataType=LeadStatus`,
    // GetAffiliateCommissionList: `${apiURL}api/Common/GetSupportDataByDataType?DataType=LeadAffilateCommission`,
    GetBusinessTypeDropDownList: `${apiURL}api/Common/GetBusinessTypeDropDownList`,
  },
  SignupLeadDetails: {
    Edit: `${apiURL}api/SignupLeadDetails/Edit`,
    GetSignupLeadItemDetailsByGUID: `${apiURL}api/SignupLeadDetails/GetSignupLeadDetailsByGUID`,
    USAddressVerification: `${apiURL}api/SignupLeadDetails/VerifyAddress`
  },
  SignUp: {
    GetPackageList: `${apiURL}api/Signup/GetPackageList`,
    GetItemImage: `${apiURL}api/Signup/GetItemImage`,
    GetItemList: `${apiURL}api/Signup/GetItemList`,
    CreateSignupLeadDetails: `${apiURL}api/SignupLeadDetails/Create`,
    CreateSignupLeadItem: `${apiURL}api/SignupLeadItemDetails/Create`,
    CreateSignupLeadSmartshipItem: `${apiURL}api/SignupLeadSmartshipItemDetails/Create`,
    LeadItemDetailsList: `${apiURL}api/SignupLeadItemDetails/ItemDetailsList`,
    LeadSmartshipItemDetailsList: `${apiURL}api/SignupLeadSmartshipItemDetails/GetSmartshipItemList`,
    LeadItemRemoveFromCart: `${apiURL}api/SignupLeadItemDetails/Delete`,
    OrderItemValidation: `${apiURL}api/SignUp/OrderItemValidationSummary`,
    CheckoutProcess: `${apiURL}api/SignUp/ProceedCheckout`,
    CheckEmailDetails: `${apiURL}api/SignUp/CheckEmailExistence`,
    CheckReplicationSiteDetails: `${apiURL}api/SignUp/CheckReplicationSiteNameExistence`,
    GetReferenceId: `${apiURL}api/SignUp/GetReferenceId`,
    GetRandomReferenceId: `${apiURL}api/SignUp/GetRandomReferenceId`,
    GetItemDetailsByItemId: `${apiURL}api/SignUp/GetSignupDetailsByItemId`,
    GetItemListByIds: `${apiURL}api/SignUp/GetItemListByIds`,
    GetTaxDetail: `${apiURL}api/SignUp/GetTaxDetail`,
    AffiliateSignUp: `${apiURL}api/AffiliateRegistration/Register`,
  },
  IBOSignup:{
    CheckReplicationSiteDetails: `${apiURL}api/IBOSignUp/CheckExistingReplicationSiteName`,
    GetReferenceId: `${apiURL}api/IBOSignUp/GetRefId`,
    GetRandomReferenceId: `${apiURL}api/IBOSignUp/GetRandomRefId`,
    CheckoutProcess: `${apiURL}api/IBOSignUp/IBOSignUpCheckout`,
    GetUserDetails: `${apiURL}api/user/GetUserDetails`,
    GetSignUpPackageTotalAmount: `${apiURL}api/IBOSignUp/GetSignUpPackageTotalAmount`,
    GetRefById: `${apiURL}api/IBOSignUp/GetRefNameByRefId`,
    UpgradeProcess: `${apiURL}api/IBOSignUp/IBOSignUpUpgrade`,
  },
  UserManagement: {
    Authorization: `${apiURL}api/User/Authorization`,
    EditUserLoginInfo: `${apiURL}api/User/EditUserLoginInfo`,
  },
  User: {
    GetPublicInfoById: `${apiURL}api/User/GetUserPublicInformationDetails`,
    GetPrivateInfoById: `${apiURL}api/User/GetUserPrivateInformationDetails`,
    EditPublicInfo: `${apiURL}api/User/EditUserPublicInformation`,
    EditPrivateInfo: `${apiURL}api/User/EditUserPrivateInformation`,
    GetUserSpecificDetails: `${apiURL}api/User/GetSpecificUserDetails`,
    GetUserProfilePicDetails: `${apiURL}api/User/GetUserProfilePicDetails`,
    ChangePassword: `${apiURL}api/User/ChangePassword`,
    GetDashboardDetails: `${apiURL}api/User/GetDashboardDetails`,
  },
  Sponsor: {
    GetSponsorInformation: `${apiURL}api/Customer/GetSponsorDetailsById`
  },
  ReferralTree: {
    GetReferralTreeList: `${apiURL}api/ReferralTree/GetReferralTreeList`
  },
  PlacementPreference: {
    GetPlacementPreferenceDetails: `${apiURL}api/User/GetPlacementPreferenceDetails`,
    EditPlacementPreference: `${apiURL}api/User/EditPlacementPreference`,
    Authorization: `${apiURL}api/User/Authorization`,
  },
  Order: {
    OrderList: `${apiURL}api/Order/OrderListByUserId`,
    OrderDetails: `${apiURL}api/Order/GetOrderById`,
    ExistingCardList: `${apiURL}api/PaymentProfile/GetExistingCardListByUserId`,
    OrderRepayment: `${apiURL}api/Order/OrderRepayment`,
  },
  SmartShipInvoice: {
    SmartShipInvoiceList: `${apiURL}api/SmartshipInvoice/SmartshipInvoiceListByLoginUserId`,
    SmartShipInvoiceStatusUpdate: `${apiURL}api/SmartshipInvoice/StatusUpdate`,
    GetSmartShipList: `${apiURL}api/SmartshipInvoice/GetSmartShipList`,
    CreateSmartShipInvoice: `${apiURL}api/SmartshipInvoice/Create`,
    EditSmartShipInvoice: `${apiURL}api/SmartshipInvoice/Edit`,
    RunSmartShipInvoice: `${apiURL}api/SmartshipInvoice/RunSmartshipInvoiceByID`,
    GetUserPaymentMethodByUserId: `${apiURL}api/PaymentMethod/GetUserPaymentMethodByUserId`,
    UserPaymentMethodModify: `${apiURL}api/PaymentMethod/UserPaymentMethodModify`,
  },
  iWallet: {
    iWalletList: `${apiURL}api/iWallet/iWalletListByLoginUserId`,
    GetiWalletSummary: `${apiURL}api/iWallet/GetiWalletSummaryByLoginUserId`,
    GetExternalPaymentConfigurationById: `${apiURL}api/ExternalPaymentConfiguration/GetExternalPaymentConfigurationById`,
    ExternalPaymentConfigurationList: `${apiURL}api/ExternalPaymentConfiguration/ExternalPaymentConfigurationList`,
    CreateiWallet: `${apiURL}api/iWallet/IWalletWithdrawal`,
    SendAccessCode: `${apiURL}api/iWallet/AccessCode`,
    CompareAccessCode: `${apiURL}api/iWallet/CompareAccessCode`,
  },
  FeeConfiguration: {
    FeeConfigurationList: `${apiURL}api/FeeConfiguration/FeeConfigurationList`
  },
  BackOfficeMenu: {
    GetBackOfficeMenuList: `${apiURL}api/BackOfficeMenu/List`,
  },
  MediaPageDetails: {
    MediaPageDetailsListByMediaId: `${apiURL}api/MediaPageDetails/MediaPageDetailListByMediaId`,
    GetMediaPagePdfFile: `${apiURL}api/MediaPageDetails/GetMediaPagePdfFile`,
    AssignMediaPageQualification: `${apiURL}api/MediaPageDetails/AssignMediaPageQualification`
  },
  BinaryPlacementTree: {
    BinaryPlacementTreeList: `${apiURL}api/BinaryPlacementTree/BinaryPlacementTreeList`,
    CheckAccountIdInPlacementTree: `${apiURL}api/BinaryPlacementTree/CheckAccountIdForPlacementTree`,
    GetBottomPlacementTree: `${apiURL}api/BinaryPlacementTree/GetBottomPlacementTree`,
  },
  SmartCart: {
    GetSmartCartDetailsByUserId: `${apiURL}api/SmartCart/GetSmartCartDetailsByAcctID`,
    GetSmartCartDetailsByGUID: `${apiURL}api/SmartCart/GetSmartCartDetailsByGUID`,
    DeleteSmartCartById: `${apiURL}api/SmartCart/UpdateStatus`,
    GoToSmartCart: `${apiURL}api/SmartCart/GoToSmartCart`,
  },
  PersonallySponsored: {
    PersonallySponsoredList: `${apiURL}api/ReferralTree/GetPersonallySponsoredList`
  },
  PaymentProfile: {
    CreatePaymentProfile: `${apiURL}api/PaymentProfile/Create`,
    PaymentProfileList: `${apiURL}api/PaymentProfile/PaymentProfileList`,
    PaymentProfileDelete: `${apiURL}api/PaymentProfile/Delete`,
    PaymentProfileSetDefault: `${apiURL}api/PaymentProfile/SetAsDefault`
  },
  LegalDocument: {
    LegalDocumnetDetailByTypeAndName: `${apiURL}api/LegalDocument/GetLegalDocumentByTypeName`,
  },
  SystemMediaConfiguration: {
    GetSystemMediaConfigurationDetails: `${apiURL}api/SystemMediaConfiguration/GetSystemMediaConfigurationDetails`,
  },
  UpGradeAccount: {
    UpgradeUserAccount: `${apiURL}api/UpgradeAccount/UpgradeUserAccount`,
  },
  Dashboard: {
    DashboardNewSignUpDetails: `${apiURL}api/Dashboard/GetDashboardNewSignUpList`,
    DashboardCommissionDetails: `${apiURL}api/Dashboard/GetDashboardCommissionList`,
    DashboardRetailCustomerOrderDetails: `${apiURL}api/Dashboard/GetDashboardRetailCustomerOrdersList`,
    DashboardUserOrderDetails: `${apiURL}api/Dashboard/GetDashboardUserOrdersList`,
    DashboardUserSmartshipInvoiceDetails: `${apiURL}api/Dashboard/GetDashboardUserSmartshipInvoiceList`,
    KnowledgeBaseURL: `https://byldnetwork.zendesk.com/hc/en-us/articles/27069402919060-BYLD-Network-Monthly-Subscription`
  },
  RetailShopping: {
    GetUserRoleByEmail: `${apiURL}api/RetailShopping/GetUserRoleByEmail`,
  },
  SeamlessLogin: {
    SeamlessLoginCreate: `${apiURL}api/SeamlessLogin/Create`,
    SeamlessLoginConsume: `${apiURL}api/SeamlessLogin/Consume`
  },
  WebsiteName: {
    UpdateWebsiteName: `${apiURL}api/WebsiteName/UpdateWebsiteName`,
  },
  Rewards: {
    CheckAccessAPI: `${apiURL}api/AccessAPI/CheckAccessAPI`,
    CheckRewardPageAccessCriteria: `${apiURL}api/AccessAPI/CheckRewardPageAccessCriteria`,
    ReceiveMyDiscount: `${apiURL}api/AccessAPI/ReceiveMyDiscount`,
  },
  LeadCollections: {
    CraeteLeadCollection: `${apiURL}api/LeadCollection/CraeteLeadCollection`,
    EditLeadCollection: `${apiURL}api/LeadCollection/EditLeadCollection`,
    GetLeadCollectionListByUserId: `${apiURL}api/LeadCollection/GetLeadCollectionListByUserId`,
    GetLeadCollectionDetailsById: `${apiURL}api/LeadCollection/GetLeadCollectionDetailsById`,
    DeleteUplodedMedia: `${apiURL}api/LeadCollection/LeadCollectionMediaDelete`,
    LeadCollectionDelete: `${apiURL}api/LeadCollection/LeadCollectionDelete`,
    LeadMentorSearchList: `${apiURL}api/LeadCollection/GetLeadMentorSearchList`,
    LeadAffiliateList: `${apiURL}api/LeadCollection/GetLeadAffiliateListByUserId`,
    CrediFormLink: `https://powerforms.docusign.net/3efb4603-69f4-4244-92eb-7781ee9ba813?env=na4&acct=c66ffb7a-b8f8-40c3-a7a4-d000e2f20c5b&accountId=c66ffb7a-b8f8-40c3-a7a4-d000e2f20c5b`,
    ApplyFinancingLink: `https://www.goodleap.dev/alkavida/e7af2721-9abd-4c25-8657-c7a7523590b5`,
    ShopResidentialLink: `https://alkavidainc.com/collections/residential`
  },
  Reports: {
    DualTeamBonusCommissionReport: `${apiURL}api/Reports/DualTeamBonusCommissionReport`,
    DualTeamBonusVolumeReport: `${apiURL}api/Reports/DualTeamBonusVolumeReport`,
    DailyDualTeamBonusVolumeReport: `${apiURL}api/Reports/DailyDualTeamBonusVolumeReport`,
  }
}

export { urlConstant as default }
